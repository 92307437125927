import { DwollaCustomerStatus } from '../../base/common/enums/dwolla-customer-status';

export default defineNuxtRouteMiddleware(() => {
  const { data } = useAuth();
  const $auth = data.value;

  if (
    $auth?.snapStatus !== DwollaCustomerStatus.Document &&
    $auth?.snapStatus !== DwollaCustomerStatus.Retry &&
    ($auth?.verifiedWithDwolla !== null || $auth?.verifiedWithPlaid !== null)
  ) {
    return navigateTo('/');
  }
});
